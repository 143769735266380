<template>
    <div
        @mouseover="hover = true"
        @mouseleave="hover = false"
        class="flex flex-col justify-between bg-white rounded-lg shadow-md"
    >
        <div class="p-4">
            <div class="flex items-center justify-between">
                <div class="font-medium truncate text-neutral-700">{{ asset.title }}</div>
                <div class="flex items-center ml-1 space-x-2 ">
                    <template v-if="isAggregator">
                        <template v-if="!assetsInContracts.includes(asset.id)">
                            <div
                                class="text-white bg-secondary-700 px-2 py-0.5 text-sm rounded-full whitespace-nowrap"
                                v-if="inCart"
                            >
                                Selected
                            </div>
                        </template>
                        <div
                            class="text-primary-500 bg-primary-100 px-2 py-0.5 text-sm rounded-full whitespace-nowrap"
                            v-else
                        >
                            In Contract
                        </div>
                    </template>
                </div>
            </div>
            <div class="text-sm text-neutral-500 line-clamp-2" v-if="asset.description" v-tooltip="asset.description">
                {{ asset.description }}
            </div>
        </div>
        <div class="flex flex-wrap items-center p-4 mt-2 text-sm text-neutral-500">
            <div class="flex items-center mr-4 space-x-1 ">
                <user-icon class="w-4 h-4" />
                <div v-if="asset.provider.orgBusinessName">{{ asset.provider.orgBusinessName }}</div>
                <div v-else>{{ asset.provider.firstName }} {{ asset.provider.lastName }}</div>
            </div>
            <div class="flex flex-wrap items-center text-neutral-500">
                <div class="flex items-center mr-4 space-x-1">
                    <calendar-icon class="w-4 h-4" />
                    <div>Created on {{ dayjs(asset.createdAt).format('MMM D, YYYY') }}</div>
                </div>
                <div class="flex items-center space-x-1 ">
                    <calendar-icon class="w-4 h-4" />
                    <div>Updated on {{ dayjs(asset.updatedAt).format('MMM D, YYYY') }}</div>
                </div>
            </div>
        </div>

        <div class="flex -mt-px border-t divide-x divide-neutral-200 border-neutral-200">
            <div class="flex flex-1 w-0">
                <button
                    type="button"
                    :disabled="false"
                    @click="$router.push({ name: 'Asset Details', params: { id: asset.id, title: asset.title } })"
                    class="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium border border-transparent rounded-bl-lg text-primary-400 hover:text-primary-600 disabled:pointer-events-none disabled:opacity-50"
                >
                    <search-icon class="w-4 h-4" />
                    <div class="ml-3">View Details</div>
                </button>
            </div>
            <div class="flex flex-1 w-0 -ml-px" v-if="!assetsInContracts.includes(asset.id) && isAggregator">
                <button
                    v-if="!inCart"
                    type="button"
                    :disabled="!canBuy"
                    @click="addToCart"
                    class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium border border-transparent rounded-br-lg text-primary-400 hover:text-primary-600 disabled:pointer-events-none disabled:opacity-50"
                >
                    <svg width="1.1em" height="1.1em" viewBox="0 0 512 512">
                        <path
                            fill="currentColor"
                            d="M459.26 96L328 225.7V96h-34.525L168 223.267V16H16v480h480V96ZM464 464H48V48h88v216h36.778L296 139.018V264h38.764L464 136.3Z"
                        ></path>
                        <path
                            fill="currentColor"
                            d="M136 328v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-72h32v32h-32zm0 72h32v32h-32z"
                        ></path>
                    </svg>
                    <div class="ml-3">Add To Cluster</div>
                </button>
                <button
                    v-else
                    type="button"
                    @click="removeFromCart"
                    class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium text-red-500 border border-transparent rounded-br-lg hover:text-red-600 disabled:pointer-events-none disabled:opacity-50"
                >
                    <svg width="1.1em" height="1.1em" viewBox="0 0 512 512">
                        <path
                            fill="red"
                            d="M459.26 96L328 225.7V96h-34.525L168 223.267V16H16v480h480V96ZM464 464H48V48h88v216h36.778L296 139.018V264h38.764L464 136.3Z"
                        ></path>
                        <path
                            fill="red"
                            d="M136 328v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-48v8h32v-32h-32v24zm0 48h32v32h-32zm80-72h32v32h-32zm0 72h32v32h-32z"
                        ></path>
                    </svg>
                    <div class="ml-3">Remove From Cluster</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { SearchIcon } from '@heroicons/vue/outline';
import { UserIcon, CalendarIcon } from '@heroicons/vue/solid';
import store from '@/store';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'AssetCard',
    components: {
        UserIcon,
        CalendarIcon,
        SearchIcon,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
        inCart: {
            type: Boolean,
            default: false,
        },
        isAggregator: {
            type: Boolean,
            default: false,
        },
        selectedProvider: {
            type: Number,
            default: null,
        },
        assetsInContracts: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, { emit }) {
        const hover = ref(false);
        const isBlockchainEnabled = ref(!!process.env.VUE_APP_ETH_NODE);
        const hasWallet = computed(() => store.getters.hasWallet);

        const addToCart = () => {
            emit('add-to-cart', props.asset);
        };

        const removeFromCart = () => {
            emit('remove-from-cart', props.asset.id);
        };

        const canBuy = computed(
            () =>
                (!isBlockchainEnabled.value || (isBlockchainEnabled.value && hasWallet.value)) &&
                (!props.selectedProvider ||
                    (props.selectedProvider && props.asset.providerId === props.selectedProvider)),
        );

        return {
            dayjs,
            addToCart,
            removeFromCart,
            isBlockchainEnabled,
            hasWallet,
            canBuy,
            hover,
        };
    },
});
</script>
